<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1 class="titulo">IEA - Estrategias en el Aula</h1>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">
          Enero 2025 – Campaña: El fentanilo te mata: Aléjate de las drogas, elige ser feliz
        </h2>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-12">
        <p>
          La <b>Secretaría de Educación Pública (SEP)</b>, a través del
          <b>Instituto de Educación de Aguascalientes (IEA)</b>, impulsa la
          <b>Estrategia en el Aula: Prevención de Adicciones
          en el Estado de Aguascalientes,</b>con la difusión de la <b>Campaña: “El fentanilo te
          mata: Aléjate de las drogas, elige ser feliz”</b>, iniciando actividades a partir del 13 de
          enero de 2025.
        </p>
        <p>
          Esta estrategia tiene el objetivo de fortalecer las escuelas como entornos en los que se
          promueva la salud y prevengan las adicciones entre las y los adolescentes mexicanos.
        </p>
        <p>
          La presidencia de la República, en coordinación con la Secretaria de Educación Pública
          y los Gobiernos Estatales, impulsa una estrategia destinada a prevenir el consumo de
          drogas entre estudiantes de educación secundaria y media superior. Esta iniciativa
          busca fomentar el conocimiento y la reflexión sobre las características y los efectos
          perjudiciales de las sustancias psicoactivas en la salud, con un enfoque especial en la
          población adolescente.
        </p>
        <p>
          Para el logro de este objetivo, se proporciona al personal docente de herramientas
          pedagógico-didácticas para sensibilizar sobre los daños irreversibles causados por el
          consumo de drogas desde sus primeras etapas, además de fomentar en las y los
          estudiantes habilidades de autocuidado y toma de decisiones informadas para
          preservar su salud física y mental.
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <a href="https://www.gob.mx/lineadelavida">
          <h6>Prevención de adicciones</h6>
        </a>
        <a href="https://www.gob.mx/cms/uploads/attachment/file/964368/Guia_docentes_CSP_2024_271224_WEB.pdf">
          <h6>Guía para docentes "Prevención de adicciones".</h6>
        </a>
        <a href="https://www.gob.mx/cms/uploads/attachment/file/964367/_Orientaciones_CSP_2024_271224_WEB.pdf">
          <h6>Orientaciones para Madres, Padres y Familias.</h6>
        </a>
        <a href="https://www.gob.mx/cms/uploads/attachment/file/965779/ALEJATE_DE_LAS_DROGAS_carteles_27x41_WEB.pdf">
          <h6>Carteles. El Fentanilo mata. Aléjate de las drogas.</h6>
        </a>
        <a href="https://www.gob.mx/lineadelavida" target="_blank" rel="noopener noreferrer">
          <img src="https://www.iea.gob.mx/img/sistema-educativo/estrategia-aula/QR-El-fentanilo-mata.png" alt="..." class="img-fluid rounded" />
        </a>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">
          Estrategia en el Aula: Prevención de Adicciones, ciclo escolar 2023-2024
        </h2>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-8">
        <p>
          La <b>Secretaría de Educación Pública (SEP)</b>, a través del
          <b>Instituto de Educación de Aguascalientes (IEA)</b>, impulsa la
          <b>Estrategia en el Aula: Prevención de Adicciones</b> en el Estado de
          Aguascalientes. Esta estrategia tiene el objetivo de instrumentar
          acciones en el aula y una campaña de difusión que contribuyan a la
          prevención del consumo de drogas entre estudiantes de secundaria y de
          educación media superior, a través de espacios de reflexión y mensajes
          de comunicación asertivos.
        </p>
        <p>
          Para cumplir este objetivo, se proporciona una guía que ha sido
          diseñada para las y los docentes como figuras clave que, desde su
          cercanía con el estudiantado, contribuyen a prevenir situaciones de
          riesgo. Para cumplir con este propósito, el documento brinda
          información sobre las consecuencias y daños irreversibles que ocasiona
          el consumo de las drogas presentadas en cada apartado.
        </p>
      </div>
      <div class="col-12 col-lg-4">
        <a href="IEA/sistema-educativo/estrategia-aula/Prevención-de-Adicciones-Guia-Docentes.pdf" target="_blank" rel="noopener noreferrer">
          <img src="https://www.iea.gob.mx/img/sistema-educativo/estrategia-aula/guia-docentes.png" alt="..." class="img-fluid rounded" />
        </a>
      </div>
    </div>

    <div class="row row-cols-1 row-cols-lg-3">
      <div class="col">
        <img src="https://www.iea.gob.mx/img/sistema-educativo/estrategia-aula/cartel-1.png" alt="..." class="img-fluid">
      </div>
      <div class="col">
        <img src="https://www.iea.gob.mx/img/sistema-educativo/estrategia-aula/cartel-2.png" alt="..." class="img-fluid">
      </div>
      <div class="col">
        <img src="https://www.iea.gob.mx/img/sistema-educativo/estrategia-aula/cartel-3.png" alt="..." class="img-fluid">
      </div>
    </div>

    <div class="row mt-5">
      <div class="col">
        <p class="fw-bold">
          Para mayor información sobre la Estrategia en el Aula: Prevención de
          Adicciones, visita la página oficial de la SEP:
        </p>
        <div class="banner-body-left bg_dg-6">
          <img src="https://www.iea.gob.mx/img/sistema-educativo/estrategia-aula/bg-banner.png" alt="..." class="img-fluid" />
          <div class="baninfo-wrapper">
            <p>Estrategia en el Aula: Prevención de Adicciones <br />
              #EstrategiaEnElAula #LaFelicidadEstáEnTi #NoEsJuego
              #SiTeDrogasTeDañas
            </p>
            <div class="d-flex align-items-end flex-column">
              <a href="https://estrategiaenelaula.sep.gob.mx" target="_blank" class="btn btn-principal-light float-end">
                Ir al sitio
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :infoManager="{
          name: 'Instituto de Educación de Aguascalientes',
          department: 'Instituto de Educación de Aguascalientes',
          phone: '449 910 5600',
          updatedAt: '21/01/2025',
        }" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RIComponent from "@/components/RIComponent.vue";

export default {
  name: "EstartegiaAulaView",
  components: {
    RIComponent,
  },
};
</script>

<style lang="scss" scoped>

</style>
