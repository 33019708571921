<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <div v-if="!slider" class="loading" :style="'aspect-ratio: ' + aspectRatio + ';'"></div>

        <div v-else>
          <div v-if="loading || slider.slides.length == 0" class="loading" :style="'aspect-ratio: ' + slider.aspectRatio + ';'"></div>
          
          <div v-else class="sliderGea swiper-container rounded">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="slide of [...slider.slides].reverse()">
                <router-link :to="slide.link.replace('https://www.iea.gob.mx', '')" v-if="internalLink(slide.link)">
                  <img :src="slide.base64ImageUrl" alt="..." class="img-fluid" :style="'aspect-ratio: ' + slider.aspectRatio + ';'" />
                </router-link>
                <a :href="slide.link" target="_blank" rel="noopener noreferrer" v-else>
                  <img :src="slide.base64ImageUrl" alt="..." class="img-fluid" :style="'aspect-ratio: ' + slider.aspectRatio + ';'" />
                </a>
              </div>
            </div>
            <div class="swiper-pagination"></div>
            
            <!-- <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div> -->

            <!-- <div class="swiper-scrollbar"></div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SliderComponent",
  props: {
    sliderId: {
      type: Number,
      required: true
    },
    aspectRatio: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      slider: null,
      loading: true
    }
  },
  created() {
    gea.iwin(() => {
      axios.get('https://servicios.iea.edu.mx/ieaws/ws-sgc/website/sliders/' + this.sliderId)
        .then((response) => {
          this.loading = false;
          this.slider = response.data.data;
          gea.iwin(() => {
            const swiper = new Swiper(".swiper-container", {
              observer: true,
              loop: true,
              speed: 1000,
              autoplay: {
                delay: 5000,
                disableOnInteraction: false
              },
              pagination: {
                el: ".swiper-pagination",
                clickable: true
              },
              // navigation: {
              //   nextEl: ".swiper-button-next",
              //   prevEl: ".swiper-button-prev"
              // },
              // scrollbar: {
              //   el: '.swiper-scrollbar'
              // }
            });
          });
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  methods:{
    internalLink(link) {
      return link.includes('https://www.iea.gob.mx');
    }
  }
}
</script>

<style lang="scss" scoped>

</style>