<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1 class="titulo">IEA - Organigrama</h1>
        <OrganizationChart :datasource="ds" style="width: 100%;" />
        <div class="d-flex justify-content-center">
          <a href="https://www.iea.gob.mx/IEA/somos/Organigrama.pdf" target="_blank" class="btn btn-principal">
            <span class="iconify" data-icon="material-symbols:download" width="25" height="25" data-inline="false"></span>
            Descargar Organigrama
          </a>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :infoManager="{
          name: 'Alejandra Ortiz Serna',
          department: 'Departamento de Desarrollo Institucional',
          phone: '449 910 5600 ext. 4185',
          updatedAt: '08/01/2025'
        }" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import OrganizationChart from "vue3-organization-chart";
import RIComponent from '@/components/RIComponent.vue';

import "vue3-organization-chart/dist/orgchart.css";

import organigramaData from "@/assets/json/organigramaData.json";

export default {
  name: "OrganigramaView",
  components: {
    OrganizationChart,
    RIComponent
  },
  data() {
    return {
      ds: organigramaData
    };
  },
  mounted() {
    let isDragging = false;
    let startX, startY, scrollLeft, scrollTop;

    const container = document.getElementsByClassName('chartOrgchart')[0];

    container.addEventListener('mousedown', handleStart);
    container.addEventListener('touchstart', handleStart);

    container.addEventListener('mouseup', handleEnd);
    container.addEventListener('touchend', handleEnd);

    container.addEventListener('mousemove', handleMove);
    container.addEventListener('touchmove', handleMove);

    function handleStart(e) {
      isDragging = true;
      const touch = e.type === 'touchstart' ? e.touches[0] : e;
      startX = touch.pageX - container.offsetLeft;
      startY = touch.pageY - container.offsetTop;
      scrollLeft = container.scrollLeft;
      scrollTop = container.scrollTop;
      container.style.cursor = 'grabbing';
    }

    function handleEnd() {
      isDragging = false;
      container.style.cursor = 'grab';
    }

    function handleMove(e) {
      if (!isDragging) return;
      e.preventDefault();
      const touch = e.type === 'touchmove' ? e.touches[0] : e;
      const x = touch.pageX - container.offsetLeft;
      const y = touch.pageY - container.offsetTop;
      const walkX = (x - startX) * 2;
      const walkY = (y - startY) * 2;
      container.scrollLeft = scrollLeft - walkX;
      container.scrollTop = scrollTop - walkY;
    }
  }
};
</script>

<style lang="scss" scoped>

</style>
