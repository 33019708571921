<template>
  <div class="container">
    <div class="row" id="gabinete-cards">
      <div class="col">
        <div class="card-funcionario" v-if="!loading">
          <div class="funcionario-info">
            <p class="funcionario-puesto text-end">Aguascalientes, Ags., {{ formatDate(news.date) }}</p>
            <p class="funcionario-nombre text-center mt-3">{{ news.title }}</p>
          </div>
          <img :src="news.newsImages[0]?.base64ImageUrl" alt="..." class="img-fluid rounded mx-auto d-block" style="width: 85% !important" v-if="news.newsImages?.length > 0" />
          <p class="funcionario-texto" v-for="paragraph of news.body.split('\n')">
            {{ paragraph }}
          </p>
          <div class="container">
            <div class="row g-3">
              <div class="col" v-for="image of news.newsImages">
                <img :src="image.base64ImageUrl" alt="..." class="img-fluid rounded shadow" style="max-height: 150px;" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NoticiaView',
  data() {
    return {
      news: Object,
      loading: true
    }
  },
  created() {
    gea.iwin(() => {
      axios.get("https://servicios.iea.edu.mx/ieaws/ws-sgc/website/news/" + this.$route.params.id)
        .then((response) => {
          this.loading = false;
          this.news = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
  methods: {
    formatDate(dateString) {
      const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
      return new Date(dateString).toLocaleDateString('es-ES', options);
    }
  }
}
</script>

<style lang="scss" scoped>

</style>