<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1 class="titulo">IEA - Dirección de Carrera del Magisterio</h1>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">Convocatorias</h2>
        <ConvocatoriasComponent :calls="calls" />
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">
          Proyecto/Estrategia para Mejora de los Procesos de Enseñanza,
          Promoción Horizontal 2022
        </h2>
        <div class="banner_sm bg_dg-2 mb-3">
          <div class="item1">
            <p class="titulo">
              Proyecto/Estrategia para Mejora de los Procesos de Enseñanza
            </p>
          </div>
          <div class="item2">
            <p class="contenido">Promoción Horizontal 2022</p>
          </div>
          <div class="item3">
            <a href="https://forms.gle/GDiBUgjTeh1qkQiK9" target="_blank" class="btn btn-principal-light">
              Ir al formulario
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">Proceso de Admisión 2023-2024</h2>
        <div class="banner_sm bg_dg-4 mb-3">
          <div class="item1">
            <p class="titulo">Formulario de documentación</p>
          </div>
          <div class="item2">
            <p class="contenido">Proceso de Admisión 2023-2024</p>
          </div>
          <div class="item3">
            <a href="https://forms.gle/6qhpGPNvXkUhUHU56" target="_blank" class="btn btn-principal-light">
              Ir al formulario
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col">
        <div class="banner_sm"
          style="background-image: linear-gradient(0deg, rgba(41, 60, 152, 0.5) 23.02%, rgba(41, 60, 152, 0.357) 80.31%), url('https://www.iea.gob.mx/img/bg-banners.png');">
          <div class="item1">
            <p class="titulo">Dirección de Carrera del Magisterio</p>
          </div>
          <div class="item2">
            <p class="contenido">
              Av. Tecnológico No. 601, Fracc. Ojocaliente, C.P. 20196
              Aguascalientes, Ags. <br />
              Tel. 449 910 5600 ext. 4374
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :infoManager="{
          name: 'Gabriela Rocha Colis',
          department: 'Dirección de Carrera del Magisterio',
          phone: '449 910 5600 ext. 4294 / 4367',
          updatedAt: null,
        }" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ConvocatoriasComponent from "../../components/ConvocatoriasComponent.vue";
import RIComponent from "@/components/RIComponent.vue";

export default {
  name: "CarreraMagisterioView",
  components: {
    ConvocatoriasComponent,
    RIComponent,
  },
  data() {
    return {
      calls: [
        {
          title: "Convocatoria para el Proceso de Promoción a Horas Adicionales en Educación Básica, Ciclo Escolar 2025-2026",
          type: "Convocatoria",
          links: [
            {
              description: "Ver Convocatoria",
              link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/convocatorias/CVT-Proceso-de-Promoción-a-Horas-Adicionales-en-Educación-Básica.pdf",
              validity: "15/01/2025 - 14/07/2025",
            }
          ],
          validity: "15/01/2025 - 14/07/2025",
        },
        {
          title: "Convocatoria para el Reconocimiento a la Práctica Educativa en Educación Básica, 2025",
          type: "Convocatoria",
          links: [
            {
              description: "Ver Convocatoria",
              link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/convocatorias/CVT-Reconocimiento-a-la-Práctica-Educativa-en-Educación-Básica.pdf",
              validity: "19/12/2024 - 13/06/2025",
            }
          ],
          validity: "19/12/2024 - 13/06/2025",
        },
        {
          title: "Convocatoria del Proceso de Promoción a Categorías con Funciones Directivas o de Supervisión en Educación Básica, Ciclo Escolar 2025-2026",
          type: "Convocatoria",
          links: [
            {
              description: "Ver Convocatoria",
              link: "https://www.iea.gob.mx/IEA/convocatorias-y-becas/convocatorias/CVT-Proceso-de-Promoción-a-Categorías-con-Funciones-Directivas-o-de-Supervisión-en-Educación-Básica.pdf",
              validity: "13/12/2024 - 31/07/2025",
            }
          ],
          validity: "13/12/2024 - 31/07/2025",
        }
      ],
    };
  },
};
</script>

<style lang="scss" scoped>

</style>
