<template>
  <div class="position-relative" v-if="loading">
    <div class="position-fixed top-50 start-50 translate-middle" style="z-index: 1090;">
      <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="border rounded shadow p-3">
          <h1 class="titulo c_base text-center">Buzón de Solicitudes de Información</h1>
          <form class="row" novalidate>
            <div class="col-12 mb-3">
              <label for="name" class="form-label">Nombre</label>
              <input type="text" id="name" class="form-control" v-model="emailForm.name" />
            </div>
            <div class="col-12 mb-3">
              <label for="email" class="form-label">Correo electrónico</label>
              <input type="email" id="email" class="form-control" v-model="emailForm.email" />
            </div>
            <div class="col-12 mb-3">
              <label for="message" class="form-label">Mensaje</label>
              <textarea class="form-control" id="message" rows="3" v-model="emailForm.message"></textarea>
            </div>
          </form>
          <button class="btn btn-form" :disabled="!formReady || loading" @click="sendEmail()">Enviar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { alert } from '@/assets/js/functions';

export default {
  name: 'MailboxComponent',
  props: {
    toEmail: String,
    subject: String
  },
  data() {
    return {
      emailForm: {
        name: "",
        email: "",
        message: ""
      },
      loading: false
    }
  },
  methods: {
    sendEmail() {
      let data = {
        fromName: "BUZÓN IEA",
        fromEmail: "buzon@iea.edu.mx",
        toEmail: this.toEmail,
        subject: this.subject,
        body: `
        <p>
          Nuevo mensaje en el buzón de ${this.emailForm.name.toUpperCase()} (${this.emailForm.email})
        </p>
        <p>
          ${this.emailForm.message}
        </p>
        <p>
          Atentamente,<br />
          Instituto de Educación de Aguascalientes<br />
          Dirección de Finanzas y Administración<br />
          Subdirección de Tecnologías de la Información
        </p>

        <div style="text-align: center;">
          <img src="https://www.iea.gob.mx/img/img-escudoiea-xg.png" height="75" />
        </div>
        `
      }

      // Configuración para la solicitud HTTP (axios)...
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://servicios.iea.edu.mx/ieaws/ws-gcp/email/send',
        headers: {
          'Content-Type': 'application/json',
          'X-Api-Key': '9428883ad942749c9520ee785a5ca57f17cecab67dffed303a5a2be2370fa84d'
        },
        data: data
      };

      this.loading = true;

      gea.iwin(() => {
        axios.request(config)
          .then((response) => {
            this.loading = false;

            if (response.data.Status === true) {
              alert("Hemos recibido tu correo y te responderemos a la brevedad posible. Si necesitas asistencia inmediata, por favor, no dudes en contactarnos directamente por teléfono.", 'info');
            }

            this.clearForm();
          })
          .catch((error) => {
            this.loading = false;
            
            this.clearForm();
            // console.log(error);
          });
      });
    },
    clearForm() {
      this.emailForm = {
        name: "",
        email: "",
        message: ""
      }
    }
  },
  computed: {
    formReady() {
      return this.emailForm.name !== "" && this.emailForm.email !== "" && this.emailForm.message !== "";
    }
  }
}
</script>

<style lang="scss" scoped>

</style>